import FrameComponent3 from "./frame-component3";

const FrameComponent2 = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 px-12 box-border max-w-full text-left text-lg text-gray-100 font-rajdhani lg:pl-6 lg:pr-6 lg:box-border">
      <div className="flex-1 flex flex-row items-end justify-start gap-[16px] max-w-full mq1400:flex-wrap">
        <div className="flex-1 flex flex-col items-start justify-start gap-[234.5px] min-w-[525px] max-w-full mq825:gap-[117px_234.5px] mq825:min-w-full mq450:gap-[59px_234.5px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[32px] mq450:gap-[16px_32px]">
            <h1 className="m-0 self-stretch relative text-31xl leading-[56px] font-bold font-inherit mq825:text-21xl mq825:leading-[45px] mq450:text-11xl mq450:leading-[34px]">
              Industry is acutely aware of the challenges they have in training.
            </h1>
            <b className="self-stretch relative text-chocolate">
              INDUSTRY CHALLENGES
            </b>
            <div className="self-stretch h-[315px] relative inline-block">
              <p className="[margin-block-start:0] [margin-block-end:18px]">
                <b>Most training simulators have a 24 months waiting time.</b>
              </p>
              <ul className="m-0 text-inherit pl-6">
                <li className="mb-0">
                  <span className="font-medium">
                    Jet type planes are where the waiting time is worst.
                  </span>
                </li>
                <li className="mb-0">
                  <span className="font-medium">
                    Time on simulators is competitive between pilots and other
                    roles.
                  </span>
                </li>
              </ul>
              <p className="[margin-block-start:0] [margin-block-end:18px] font-medium">
                These is an aging workforce with expectancy to retire in the
                coming decade.
              </p>
              <p className="[margin-block-start:0] [margin-block-end:18px] font-medium">
                Currently not enough instructors and/or tools.
              </p>
              <p className="[margin-block-start:0] [margin-block-end:18px] font-medium">
                There is a risk when training on physical engines due to
                potential damage to the engine.
              </p>
              <p className="[margin-block-start:0] [margin-block-end:18px]">
                <b>Saving costs is of big interest.</b>
              </p>
              <ul className="m-0 text-inherit pl-6">
                <li className="mb-0">
                  <span className="font-medium">
                    High costs of instructors taking time off to provide
                    training.
                  </span>
                </li>
                <li>
                  <span className="font-medium">
                    High costs of flights for instructors and trainees to visit
                    training facilities.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="self-stretch h-[863px] flex flex-row flex-wrap items-center justify-center p-4 box-border relative gap-[16px_24px] text-center text-29xl text-white">
            <b className="w-[773.5px] absolute !m-[0] top-[152.3px] left-[17px] text-lg uppercase inline-block text-gray-100">
              Current Costs
            </b>
            <div className="h-[251.7px] w-[230px] !m-[0] absolute top-[191.3px] left-[34.8px] overflow-hidden flex flex-row items-start justify-start pt-[14.300000000000068px] pb-[14.199999999999818px] pr-[3.400000000001455px] pl-[4.299999999999272px] box-border min-w-[230px] max-w-[230px] min-h-[150px]">
              <div className="h-[223.2px] w-[222.3px] !m-[0] absolute top-[calc(50%_-_111.55px)] left-[calc(50%_-_110.7px)] flex flex-row items-end justify-start pt-[66.69999999999993px] px-[19px] pb-[49.5px] box-border">
                <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-[50%] bg-tomato" />
                <div className="ml-[-24px] self-stretch w-[230px] flex flex-col items-start justify-start">
                  <b className="self-stretch relative z-[1] mq825:text-19xl mq450:text-10xl">
                    ~A$900
                  </b>
                </div>
                <b className="w-[183px] relative text-lg flex items-center justify-center shrink-0 z-[1] ml-[-206px]">
                  Cost of training per hour
                </b>
              </div>
            </div>
            <div className="h-[251.7px] w-[230px] !m-[0] absolute top-[191.3px] left-[288.8px] overflow-hidden flex flex-row items-start justify-start pt-[14.300000000000068px] pb-[14.199999999999818px] pr-[3.400000000001455px] pl-[4.299999999999272px] box-border min-w-[230px] max-w-[230px] min-h-[150px]">
              <div className="h-[223.2px] w-[calc(100%_-_7.7px)] absolute !m-[0] top-[calc(50%_-_111.55px)] right-[3.4px] left-[4.3px]">
                <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-tomato w-full h-full" />
                <b className="absolute top-[66.7px] left-[-4.3px] flex items-center justify-center w-[230px] z-[1] mq825:text-19xl mq450:text-10xl">
                  A$18k
                </b>
                <b className="absolute top-[127.7px] left-[19.7px] text-lg flex items-center justify-center w-[182px] z-[1]">
                  Electricity cost per month
                </b>
              </div>
            </div>
            <div className="h-[251.7px] w-[230px] !m-[0] absolute top-[191.3px] left-[542.8px] overflow-hidden flex flex-row items-start justify-start pt-[14.300000000000068px] pb-[14.199999999999818px] pr-[3.400000000001455px] pl-[4.299999999999272px] box-border min-w-[230px] max-w-[230px] min-h-[150px]">
              <div className="h-[223.2px] w-[222.3px] !m-[0] absolute top-[calc(50%_-_111.55px)] left-[calc(50%_-_110.7px)] flex flex-row items-end justify-start pt-[66.69999999999993px] px-[19.700000000000728px] pb-[49.5px] box-border">
                <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-[50%] bg-tomato" />
                <div className="ml-[-24px] self-stretch w-[230px] flex flex-col items-start justify-start">
                  <b className="self-stretch relative z-[1] mq825:text-19xl mq450:text-10xl">
                    A$2m
                  </b>
                </div>
                <b className="w-[182px] relative text-lg flex items-center shrink-0 z-[1] ml-[-206px]">
                  <span className="w-full">
                    <p className="m-0">Total cost</p>
                    <p className="m-0">per flight sim</p>
                  </span>
                </b>
              </div>
            </div>
            <div className="h-[251.7px] w-[230px] !m-[0] absolute top-[459px] left-[161.8px] overflow-hidden flex flex-row items-start justify-start pt-[14.300000000000182px] pb-[14.199999999999818px] pr-[3.400000000001455px] pl-[4.299999999999272px] box-border min-w-[230px] max-w-[230px] min-h-[150px]">
              <div className="h-[223.2px] w-[calc(100%_-_7.7px)] absolute !m-[0] top-[calc(50%_-_111.55px)] right-[3.4px] left-[4.3px]">
                <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-tomato w-full h-full" />
                <b className="absolute top-[66.7px] left-[-4.3px] flex items-center justify-center w-[230px] whitespace-nowrap z-[1] mq825:text-19xl mq450:text-10xl">
                  A$250
                </b>
                <b className="absolute top-[127.7px] left-[19.7px] text-lg flex items-center justify-center w-[182px] z-[1]">
                  Engine grounded for 6 months
                </b>
              </div>
            </div>
            <div className="h-[251.7px] w-[230px] !m-[0] absolute top-[459px] left-[415.8px] overflow-hidden flex flex-row items-start justify-start pt-[14.300000000000182px] pb-[14.199999999999818px] pr-[3.400000000001455px] pl-[4.299999999999272px] box-border min-w-[230px] max-w-[230px] min-h-[150px]">
              <div className="h-[223.2px] w-[calc(100%_-_7.7px)] absolute !m-[0] top-[calc(50%_-_111.55px)] right-[3.4px] left-[4.3px]">
                <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-tomato w-full h-full" />
                <b className="absolute top-[66.7px] left-[-4.3px] flex items-center justify-center w-[230px] z-[1] mq825:text-19xl mq450:text-10xl">
                  2-3hr
                </b>
                <b className="absolute top-[127.7px] left-[19.7px] text-lg flex items-center justify-center w-[182px] z-[1]">
                  For technical on the job training
                </b>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start min-w-[525px] min-h-[1814px] max-w-full text-center text-white mq825:min-w-full mq1400:min-h-[auto]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[189px] mq825:gap-[94px_189px] mq450:gap-[47px_189px]">
            <div className="self-stretch h-[919px] flex flex-row flex-wrap items-center justify-center p-4 box-border relative gap-[16px_24px]">
              <FrameComponent3
                lackOfInstructorsTraining={`Lack of instructors & training solutions`}
                image2345787="/image-2345787@2x.png"
              />
              <FrameComponent3
                lackOfInstructorsTraining="High costs of offline equipment"
                image2345787="/image-2345786@2x.png"
                propLeft="288.8px"
                propWidth="95.8px"
                propHeight="86.5px"
              />
              <FrameComponent3
                lackOfInstructorsTraining="High time costs of instructors"
                image2345787="/image-2345788@2x.png"
                propLeft="542.8px"
                propWidth="104.4px"
                propHeight="77.8px"
              />
              <div className="h-[230px] w-[230px] !m-[0] absolute top-[478.4px] left-[161.8px] overflow-hidden flex flex-col items-center justify-center gap-[10px] min-w-[230px] max-w-[230px] min-h-[150px]">
                <div className="w-[222.3px] h-[223.2px] !m-[0] absolute top-[calc(50%_-_111.8px)] left-[calc(50%_-_110.7px)] flex flex-row items-start justify-start">
                  <div className="self-stretch flex-1 relative rounded-[50%] bg-gray-100 shrink-0 [debug_commit:612783b]" />
                </div>
                <div className="w-full h-[181px] !m-[0] absolute top-[0px] left-[0px] flex flex-col items-start justify-start max-w-[230px] max-h-[230px] z-[1]">
                  <div className="self-stretch flex-1 flex flex-col items-center justify-end pt-[27px] px-0 pb-0">
                    <img
                      className="w-[104.4px] h-[77.8px] relative object-cover"
                      loading="lazy"
                      alt=""
                      src="/image-2345788-1@2x.png"
                    />
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start py-0 px-6">
                    <b className="flex-1 relative">
                      Retiring workforce in coming decade
                    </b>
                  </div>
                </div>
              </div>
              <div className="h-[230px] w-[230px] absolute !m-[0] top-[478.4px] left-[415.8px] overflow-hidden min-w-[230px] max-w-[230px] min-h-[230px]">
                <div className="w-[calc(100%_-_7.7px)] h-[223.2px] absolute !m-[0] top-[calc(50%_-_111.8px)] right-[3.4px] left-[4.3px]">
                  <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-gray-100 w-full h-full" />
                  <b className="absolute top-[131.8px] left-[19.7px] flex items-center justify-center w-[182px] z-[1]">
                    Extended training wait times
                  </b>
                </div>
                <div className="absolute top-[16px] left-[0px] w-[230px] h-[111px] flex flex-col items-center justify-end pt-[27px] px-0 pb-0 box-border z-[1]">
                  <img
                    className="w-[106.5px] flex-1 relative max-h-full object-cover"
                    loading="lazy"
                    alt=""
                    src="/image-2345785@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[31.999999999999943px] text-left text-gray-100 mq450:gap-[16px_31.999999999999943px]">
              <h1 className="m-0 self-stretch relative text-31xl leading-[56px] font-bold font-inherit mq825:text-21xl mq825:leading-[45px] mq450:text-11xl mq450:leading-[34px]">
                <p className="m-0">{`Access to CASA approved flight simulators typically may cost up to `}</p>
                <p className="m-0">$700-1200/hr.</p>
              </h1>
              <b className="self-stretch relative text-chocolate">
                INDUSTRY CHALLENGES
              </b>
              <div className="self-stretch h-[294px] relative inline-block">
                <p className="[margin-block-start:0] [margin-block-end:18px]">
                  <b>
                    Fixed Based simulators with no motion platform (Sim In A
                    Box) cost an estimated $590,000 USD
                  </b>
                </p>
                <ul className="m-0 text-inherit pl-6">
                  <li className="mb-2.5">
                    <span className="font-medium">
                      Plus the purchase of data/flight modelling estimated at
                      $1.5 million.
                    </span>
                  </li>
                  <li className="mb-2.5">
                    <span className="font-medium">
                      These are replicated with original EOM equipment
                    </span>
                  </li>
                  <li className="mb-2.5">
                    <span className="font-medium">
                      Required for approved training from CASA
                    </span>
                  </li>
                </ul>
                <p className="[margin-block-start:0] [margin-block-end:18px] font-medium">
                  Electricity cost is the biggest cost to running these flight
                  simulators at $18,000 per month/per simulator.
                </p>
                <p className="[margin-block-start:0] [margin-block-end:18px] font-medium">
                  Technical on the job training takes between 2-3 hours.
                </p>
                <p className="[margin-block-start:0] [margin-block-end:18px] font-medium">
                  An engine grounded for down time of 6 months costs between
                  $250,000 to $300,000
                </p>
                <p className="m-0 font-medium">
                  Using VR = time savings and physical cost savings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent2;
