import TopBar from "../components/top-bar";
import MainFrame from "../components/main-frame";
import FrameComponent2 from "../components/frame-component2";
import FrameComponent1 from "../components/frame-component1";
import FrameComponent from "../components/frame-component";

const Frame = () => {
  return (
    <div className="w-full relative bg-whitesmoke overflow-hidden flex flex-col items-start justify-start tracking-[normal]">
      <TopBar />
      <div className="w-[1920px] h-[1080px] relative bg-main-green hidden opacity-[0.04] max-w-full" />
      <section className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-4 box-border gap-[16.000000000000114px] max-w-full">
        <MainFrame />
        <FrameComponent2 />
      </section>
      <FrameComponent1 />
      <FrameComponent />
    </div>
  );
};

export default Frame;
