import { useMemo } from "react";

const FrameComponent3 = ({
  lackOfInstructorsTraining,
  image2345787,
  propLeft,
  propWidth,
  propHeight,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const image2345787IconStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);

  return (
    <div
      className="h-[251.7px] w-[230px] absolute !m-[0] top-[210.7px] left-[34.8px] overflow-hidden min-w-[230px] max-w-[230px] min-h-[150px] text-center text-lg text-white font-rajdhani"
      style={frameDivStyle}
    >
      <div className="w-[calc(100%_-_7.7px)] h-[223.2px] absolute !m-[0] top-[calc(50%_-_111.65px)] right-[3.4px] left-[4.3px]">
        <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-gray-100 w-full h-full" />
        <b className="absolute top-[127.8px] left-[19.7px] flex items-center justify-center w-[182px] z-[1]">
          {lackOfInstructorsTraining}
        </b>
      </div>
      <div className="absolute top-[0px] left-[0px] w-[230px] h-[142px] flex flex-col items-center justify-end pt-[27px] px-0 pb-0 box-border z-[1]">
        <img
          className="w-[124.4px] h-[79.5px] relative object-cover"
          loading="lazy"
          alt=""
          src={image2345787}
          style={image2345787IconStyle}
        />
      </div>
    </div>
  );
};

export default FrameComponent3;
