import FrameCol from "./frame-col";

const FrameComponent1 = () => {
  return (
    <section className="self-stretch h-[895px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-8 px-0 pb-[792.1999999999998px] box-border gap-[28px] max-w-full text-left text-13xl text-light-theme-gray-dark-blue-grey font-rajdhani lg:pt-5 lg:pb-[335px] lg:box-border mq1400:h-auto mq1400:pt-[21px] mq1400:pb-[515px] mq1400:box-border mq450:pb-[218px] mq450:box-border">
      <div className="flex flex-row items-start justify-start py-0 px-20 box-border max-w-full shrink-0 mq825:pl-10 mq825:pr-10 mq825:box-border">
        <div className="flex flex-col items-start justify-start max-w-full">
          <h2 className="m-0 h-[41px] relative text-inherit tracking-[0.02em] uppercase font-semibold font-inherit flex items-center mq825:text-7xl mq450:text-lgi">
            AIR SERVICES - SOLUTIONS
          </h2>
          <div className="h-[31px] relative text-5xl tracking-[0.02em] font-medium text-chocolate flex items-center mt-[-1.2px] mq450:text-lgi">
            Virtual Training Applications
          </div>
        </div>
      </div>
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-2 px-10 box-border [row-gap:20px] max-w-full shrink-0 text-lg text-chocolate mq1400:flex-wrap">
        <FrameCol
          image2345789="/image-2345789@2x.png"
          apronVehicleSimulation="Apron Vehicle Simulation"
          vRSimulationTrainingIsASo="VR simulation training is a solution for training operators of heavy vehicles on airport aprons. It creates a realistic apron environment, allows for the practice of vehicle operation, and offers safety and procedural training. VR is cost-effective, provides repeatability, and enables learning in diverse weather conditions. It also offers data collection and analysis, accessibility, and integration with other training methods, making it a comprehensive solution for certifying operators and improving safety and efficiency at airports."
        />
        <FrameCol
          image2345789="/image-2345790@2x.png"
          apronVehicleSimulation="Aircraft Servicing and Maintenance"
          vRSimulationTrainingIsASo="Mobile tablet training modules offer a versatile and efficient method for training aviation tire handlers, servicing, and maintenance personnel at airports. These modules offer interactive, hands-on learning experiences, enabling trainees to visualize tire concepts, safety procedures, and maintenance techniques. Mobile training reduces costs, provides remote learning opportunities, and allows for progress tracking, making it a valuable tool for enhancing safety and efficiency in training operations at airports."
          propWidth="791px"
          propMinWidth="514px"
          propPadding="8px 8px 108.09999999999992px"
        />
      </div>
    </section>
  );
};

export default FrameComponent1;
