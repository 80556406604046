const MainFrame = () => {
  return (
    <div className="self-stretch h-[967px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-8 px-20 pb-[894px] box-border max-w-full text-left text-5xl text-chocolate font-rajdhani lg:pt-[21px] lg:px-10 lg:pb-[581px] lg:box-border mq825:pt-5 mq825:pb-[378px] mq825:box-border">
      <div className="mt-[-87px] self-stretch h-[1077px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <div className="self-stretch w-[1077px] flex flex-col items-start justify-start py-[238px] pr-[42.39999999999782px] pl-[68.5px] box-border relative gap-[10px] max-w-full lg:py-[155px] lg:pr-[21px] lg:pl-[34px] lg:box-border mq825:pt-[101px] mq825:pb-[101px] mq825:box-border">
          <img
            className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/bgcircles.svg"
          />
          <div className="w-[939px] flex flex-row items-start justify-center max-w-full">
            <div className="relative tracking-[0.02em] font-medium z-[1] mq450:text-lgi">
              INDUSTRIES
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[18.80000000000291px] max-w-full text-center text-base text-gray-100">
            <div className="self-stretch flex-1 flex flex-col items-end justify-end pt-[29px] pb-[29.299999999999898px] pr-[36.89999999999782px] pl-[37px] box-border relative max-w-[calc(100%_-_140px)] mq825:pt-5 mq825:pb-5 mq825:box-border mq825:max-w-full">
              <div className="w-full h-full absolute !m-[0] top-[-0.4px] right-[0.6px] bottom-[1.4px] left-[0.1px]">
                <img
                  className="absolute h-full top-[0px] bottom-[0px] left-[190px] max-h-full w-[560px] z-[1]"
                  alt=""
                  src="/group-8966.svg"
                />
                <img
                  className="absolute top-[54.6px] left-[495px] w-[331.3px] h-[187.8px] z-[2]"
                  alt=""
                  src="/vector.svg"
                />
                <img
                  className="absolute top-[242.4px] left-[495px] w-[330.7px] h-[235.8px] z-[2]"
                  alt=""
                  src="/vector-1.svg"
                />
                <img
                  className="absolute top-[44.8px] left-[0px] w-[495px] h-[440.5px] object-contain z-[2]"
                  alt=""
                  src="/group-1000002659@2x.png"
                />
              </div>
              <div className="w-[613.6px] h-[462.7px] relative max-w-full z-[5] mq825:h-auto mq825:min-h-[462.7]">
                <div className="absolute top-[56.2px] left-[85.2px] w-[444.2px] h-[220.6px]">
                  <img
                    className="absolute top-[0px] left-[0px] w-[444.2px] h-[174.4px] object-cover z-[3]"
                    alt=""
                    src="/image-2345754@2x.png"
                  />
                  <img
                    className="absolute top-[26.5px] left-[159.6px] w-[212.2px] h-[194.1px] object-cover z-[4]"
                    alt=""
                    src="/image-136@2x.png"
                  />
                </div>
                <img
                  className="absolute top-[308.3px] left-[41.7px] rounded-41xl w-[97px] h-[97px] overflow-hidden object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/iconsimindustry@2x.png"
                />
                <div className="absolute top-[0px] left-[0px] w-[356.4px] flex flex-col items-start justify-start gap-[26px] max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[32.89999999999998px] max-w-full shrink-0">
                    <div className="h-[97px] flex flex-row items-start justify-start py-0 px-12 box-border">
                      <img
                        className="h-[97px] w-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                        loading="lazy"
                        alt=""
                        src="/iconsimindustry-1@2x.png"
                      />
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[14.899999999999975px] max-w-full">
                      <img
                        className="w-24 h-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                        alt=""
                        src="/iconsimindustry-2@2x.png"
                      />
                      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[10.599999999998545px] box-border max-w-full">
                        <div className="flex-1 flex flex-row items-end justify-between max-w-full gap-[20px] mq825:flex-wrap">
                          <img
                            className="h-[97px] w-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                            alt=""
                            src="/iconsimindustry-3@2x.png"
                          />
                          <div className="w-28 flex flex-col items-start justify-start pt-0 px-0 pb-[13.299999999999956px] box-border">
                            <div className="self-stretch relative leading-[90%] font-semibold z-[3]">
                              <p className="[margin-block-start:0] [margin-block-end:7.61px]">
                                Simulators
                              </p>
                              <p className="m-0">to suit Industries</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[312.2px] h-[97px] flex flex-row items-start justify-center py-0 px-5 box-border">
                    <img
                      className="h-[97px] w-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                      loading="lazy"
                      alt=""
                      src="/iconsimindustry-4@2x.png"
                    />
                  </div>
                </div>
                <img
                  className="absolute top-[0px] left-[480.6px] rounded-41xl w-[97px] h-[97px] overflow-hidden object-cover z-[1]"
                  alt=""
                  src="/iconsimindustry-5@2x.png"
                />
                <div className="absolute top-[73.4px] left-[438.6px] w-[174.9px] h-[389.3px] flex flex-col items-end justify-start gap-[58.10000000000002px]">
                  <img
                    className="w-[97px] h-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                    loading="lazy"
                    alt=""
                    src="/iconsimindustry-6@2x.png"
                  />
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[40.19999999999993px]">
                    <div className="self-stretch flex-1 flex flex-row items-start justify-end">
                      <img
                        className="h-[97px] w-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                        loading="lazy"
                        alt=""
                        src="/iconsimindustry-7@2x.png"
                      />
                    </div>
                    <img
                      className="w-[97px] h-[97px] relative rounded-41xl overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/iconsimindustry-8@2x.png"
                    />
                  </div>
                </div>
                <img
                  className="absolute top-[296.3px] left-[493.1px] rounded-41xl w-[97px] h-[97px] overflow-hidden object-cover z-[1]"
                  loading="lazy"
                  alt=""
                  src="/iconsimindustry-9@2x.png"
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-[44.80000000000007px] px-0 pb-0 text-left mq825:hidden mq825:pt-[29px] mq825:box-border">
              <div className="flex flex-col items-start justify-start gap-[408.4px]">
                <div className="relative leading-[100%] font-semibold z-[1]">{`Freight & Logistics`}</div>
                <div className="relative leading-[100%] font-semibold inline-block min-w-[64px] z-[1]">
                  Transport
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start relative mt-[-990px] text-13xl text-light-theme-gray-dark-blue-grey">
        <h2 className="m-0 h-[41px] relative text-inherit tracking-[0.02em] uppercase font-semibold font-inherit flex items-center z-[6] mq825:text-7xl mq450:text-lgi">
          USE CASES
        </h2>
        <div className="absolute !m-[0] right-[-189px] bottom-[-29.8px] text-5xl tracking-[0.02em] font-medium text-chocolate z-[7] mq450:text-lgi">
          Where simulations are applicable
        </div>
      </div>
    </div>
  );
};

export default MainFrame;
