const FrameComponent = () => {
  return (
    <section className="self-stretch h-[895px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-8 px-0 pb-[792.1999999999998px] box-border gap-[28px] max-w-full z-[1] text-left text-13xl text-light-theme-gray-dark-blue-grey font-rajdhani lg:pt-5 lg:pb-[335px] lg:box-border mq1400:pt-[21px] mq1400:pb-[515px] mq1400:box-border mq450:pb-[218px] mq450:box-border">
      <div className="flex flex-row items-start justify-start py-0 px-20 box-border max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border">
        <div className="flex flex-col items-start justify-start">
          <h2 className="m-0 relative text-inherit tracking-[0.02em] uppercase font-semibold font-inherit mq825:text-7xl mq450:text-lgi">
            VEHICLE SIMULATION
          </h2>
          <div className="h-[31px] relative text-5xl tracking-[0.02em] font-medium text-chocolate flex items-center mt-[-1.2px] mq450:text-lgi">{`Compact & Scalable`}</div>
        </div>
      </div>
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-2 px-10 box-border max-w-full shrink-0">
        <div className="flex-1 overflow-hidden flex flex-col items-start justify-center p-2 box-border max-w-full">
          <footer className="self-stretch overflow-hidden flex flex-col items-center justify-start pt-2 px-2 pb-[578.2000000000003px] gap-[24px] text-left text-lg text-chocolate font-rajdhani mq825:pt-5 mq825:pb-[376px] mq825:box-border">
            <b className="self-stretch relative">Apron Vehicle Simulation</b>
            <div className="self-stretch relative leading-[33px] font-medium text-gray-100">
              VR simulation training is a solution for training operators of
              heavy vehicles on airport aprons. It creates a realistic apron
              environment, allows for the practice of vehicle operation, and
              offers safety and procedural training. VR is cost-effective,
              provides repeatability, and enables learning in diverse weather
              conditions. It also offers data collection and analysis,
              accessibility, and integration with other training methods, making
              it a comprehensive solution for certifying operators and improving
              safety and efficiency at airports.
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent;
