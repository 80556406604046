import { useMemo } from "react";

const FrameCol = ({
  image2345789,
  apronVehicleSimulation,
  vRSimulationTrainingIsASo,
  propWidth,
  propMinWidth,
  propPadding,
}) => {
  const frameCol6Style = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth,
    };
  }, [propWidth, propMinWidth]);

  const costsLabelStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div
      className="w-[856px] overflow-hidden shrink-0 flex flex-col items-start justify-center pt-2 px-2 pb-[8.000000000000455px] box-border gap-[10px] min-w-[856px] max-w-full text-left text-lg text-chocolate font-rajdhani lg:min-w-full mq1400:flex-1"
      style={frameCol6Style}
    >
      <div className="self-stretch h-[361.1px] flex flex-col items-start justify-start">
        <img
          className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
          loading="lazy"
          alt=""
          src={image2345789}
        />
      </div>
      <div
        className="self-stretch overflow-hidden flex flex-col items-center justify-start pt-2 px-2 pb-[141.0999999999999px] gap-[24px] shrink-0 mq450:pt-5 mq450:pb-[92px] mq450:box-border"
        style={costsLabelStyle}
      >
        <b className="self-stretch relative">{apronVehicleSimulation}</b>
        <div className="self-stretch relative leading-[33px] font-medium text-gray-100">
          {vRSimulationTrainingIsASo}
        </div>
      </div>
    </div>
  );
};

export default FrameCol;
