const TopBar = () => {
  return (
    <header className="self-stretch h-[113px] flex flex-row items-center justify-start py-0 px-[72px] box-border relative gap-[37px] max-w-full text-left text-sm text-gray-100 font-rajdhani">
      <div className="w-[1583px] !m-[0] absolute top-[47.5px] left-[72px] flex flex-row items-start justify-start gap-[37px] max-w-full">
        <div className="flex-1 flex flex-col items-start justify-start pt-[8.5px] px-0 pb-0 box-border max-w-full">
          <div className="self-stretch h-px relative bg-gray-200" />
        </div>
        <div className="relative font-medium">RealSeriousGames.com</div>
      </div>
      <div className="h-[calc(100%_-_0.7px)] w-[339.8px] absolute !m-[0] top-[0.2px] bottom-[0.5px] left-[0px] z-[1]">
        <img
          className="absolute top-[0px] left-[0px] w-[339.8px] h-[112.3px]"
          alt=""
          src="/rectangle-4547.svg"
        />
        <img
          className="absolute top-[27.3px] left-[72px] w-[175.1px] h-[58px] object-cover z-[1]"
          loading="lazy"
          alt=""
          src="/image-2345751@2x.png"
        />
      </div>
    </header>
  );
};

export default TopBar;
